import { createContext, useEffect, useState } from "react";
import CookieConsent, { getCookieConsentValue, Cookies } from "react-cookie-consent";
import GoogleTagManager from "./GoogleTagManager";
import CampaignManager from "./CampaignManager";

export const ConsentStates = {
  undecided: "undecided",
  accepted: "accepted",
  declined: "declined",
};

export const CookieConsentContext = createContext("undecided");

export const CookieConsentProvider = ({ children }: { children: any }) => {
  const [cookieConsentChoice, setCookieConsentChoice] = useState("undecided");

  useEffect(() => setCookieConsentChoice(getCookieConsentValue() || "undecided"), []);

  useEffect(() => {
    if (cookieConsentChoice === "accepted") {
      GoogleTagManager.install();
      CampaignManager.set(Cookies);
    }
  }, [cookieConsentChoice]);

  return (
    <CookieConsentContext.Provider value={cookieConsentChoice}>
      {children}
      {cookieConsentChoice === ConsentStates.undecided && (
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "30vh",
            fontSize: "1.5rem",
            lineHeight: "3rem",
          }}
          contentStyle={{
            flex: "intial",
            textAlign: "center",
          }}
          extraCookieOptions={{ domain: "nicknack.app" }}
          cookieValue={ConsentStates.accepted}
          buttonStyle={{
            fontWeight: "bold",
            color: "white",
            backgroundColor: "var(--primary-color)",
            margin: "24px 24px 24px 14vw",
            padding: "10px 20px",
          }}
          declineButtonStyle={{
            color: "white",
            backgroundColor: "#555",
            padding: "10px 20px",
          }}
          enableDeclineButton
          declineButtonText="Decline"
          declineCookieValue={ConsentStates.declined}
          onAccept={() => {
            setCookieConsentChoice(ConsentStates.accepted);
          }}
          onDecline={() => {
            setCookieConsentChoice(ConsentStates.declined);
          }}
        >
          This website uses cookies to enhance the user experience.
          <br />
          <strong>(Trust us, worst case you'll have fun)</strong>
        </CookieConsent>
      )}
    </CookieConsentContext.Provider>
  );
};
