import styled from "@emotion/styled";
import logo from "../assets/logo.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  align-items: center;
  min-height: 100%;
  text-align: center;
  font-size: 20px;
  > img {
    margin-top: 5vh;
  }
  main[data-fill] {
    flex: 1;
  }

  h1 {
    font-size: 48px;
    margin-top: 4vh;
    margin-bottom: 4vh;
  }

  h2 {
    font-size: 30px;
    margin: 0;
  }

  p {
    margin: 0;
    line-height: 1.5em;
  }

  p.note {
    font-size: 16px;
    color: #6584e5;
    background-color: #e7edfc;
    padding: 1em;
    border-radius: 10px;
  }

  input[type="text"]:focus {
    border-color: var(--primary-color);
    outline: none;
  }

  .error {
    margin: 10px;
    color: red;
  }

  footer {
    display: flex;
    width: 100%;
    align-content: center;
    justify-content: center;
    padding: 0;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    a {
      color: #aaa;
      margin-right: 16px;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const StepLayout = ({ fill, children }: { fill?: boolean; children: any }) => {
  return (
    <Container>
      <img src={logo} alt="NickNack" />
      <main data-fill={fill || undefined}>{children}</main>
      <footer>
        <a href="/terms" target="_blank" rel="noreferrer">
          Terms
        </a>
        <a href="/privacy" target="_blank" rel="noreferrer">
          Privacy
        </a>
        <a href="/#contact-us" target="_blank">
          Contact Us
        </a>
      </footer>
    </Container>
  );
};

export default StepLayout;
