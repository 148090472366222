import Header from "../../Header";
import EpilogueArticle from "../../EpilogueArticle";
import TestimonialsArticle from "../../TestimonialsArticle";
import HowItWorksArticle from "../../HowItWorksArticle";
import MeetNickNackArticle from "../../MeetNickNackArticle";
import CoverWithHeader from "./CoverWithHeader";

const LandingPageQAAlt = () => {
  return (
    <>
      <CoverWithHeader />
      <MeetNickNackArticle />
      <TestimonialsArticle variant="short" />
      <HowItWorksArticle variant="short" />
      <EpilogueArticle variant="short" />
      <Header footer />
    </>
  );
};

export default LandingPageQAAlt;
