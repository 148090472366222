import styled from "@emotion/styled";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { useMemo } from "react";
import firebase from "../firebase";
import StepLayout from "./StepLayout";

const FBA = styled(StyledFirebaseAuth)`
  .firebaseui-idp-list {
    display: flex;
    gap: 24px;

    button.firebaseui-idp-button {
      width: 72px;
      height: 72px;

      span.firebaseui-idp-text {
        display: none;
      }

      span.firebaseui-idp-icon-wrapper {
        width: 40px;
        height: 40px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
`;

const SignUpStep1 = ({ error, chooseAccount }: { error: any; chooseAccount: boolean }) => {
  const uiConfig = useMemo<any>(() => {
    return {
      signInFlow: "popup",
      signInSuccessUrl: "#/",
      signInOptions: [
        error || chooseAccount
          ? {
              provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
              customParameters: {
                // Forces account selection even when one account is available.
                prompt: "select_account",
              },
            }
          : firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        {
          provider: "microsoft.com",
          providerName: "Microsoft",
          buttonColor: "#ffffff",
          iconUrl: "/images/microsoft_logo.png",
          loginHintKey: "login_hint",
          customParameters:
            error || chooseAccount
              ? {
                  prompt: "select_account",
                }
              : undefined,
        },
        {
          provider: "apple.com",
          providerName: "Apple",
          buttonColor: "#ffffff",
          iconUrl: "/images/apple_logo.svg",
          scopes: ["name", "email"],
          loginHintKey: "login_hint",
          customParameters:
            error || chooseAccount
              ? {
                  prompt: "select_account",
                }
              : undefined,
        },
      ],
    };
  }, [error, chooseAccount]);

  return (
    <StepLayout fill>
      <h1>Hi, nice to meet you!</h1>
      <h2>Before we enter a world of fun</h2>
      <p>
        <br />
        Choose how to continue...
      </p>
      <FBA uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      <p className="note">
        To get the most out of NickNack use your <strong>work email address</strong>!
      </p>
    </StepLayout>
  );
};

export default SignUpStep1;
