/* eslint "react/jsx-no-target-blank": "off" -- We want to pass analytics data forward. */
import styled from "@emotion/styled";
import bg_cta from "../assets/ass2/bg_cta.jpg";
import bg2_cta from "../assets/land2/bg_cta.jpg";
import btn_web_nicknack from "../assets/ass2/btn_web_nicknack.png";
import playStore from "../assets/Google_Play_Store_badge_EN.svg";
import appStore from "../assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import links from "../services/links";
import CampaignManager from "../services/CampaignManager";

const EpilogueArticleWrapper = styled.article`
  color: #ffffff;
  background-image: url(${bg_cta});
  height: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  .actions {
    display: flex;
    gap: 5px;
    padding: 5px;
    @media (min-width: 1024px) {
      gap: 20px;
    }
  }
  h1 {
    margin: 0;
    font-size: 28px;
    font-weight: 400;
    @media (min-width: 1024px) {
      font-size: 43px;
    }
  }
  p {
    font: 16px "Open Sans", sans-serif;
    margin: 1.5rem;
    line-height: 28px;
  }
  img {
    object-fit: contain;
  }
  &[data-variant="short"] {
    background-image: url(${bg2_cta});
    height: 160px;
    p {
      margin: 0;
      text-align: left;
    }
    @media (min-width: 1024px) {
      gap: 5vw;
      flex-direction: row;
    }
  }
`;

const EpilogueArticle = ({ variant }: { variant?: string }) => {
  return (
    <EpilogueArticleWrapper data-variant={variant}>
      <div>
        <h1>What's next?</h1>
        <p>Check out our apps</p>
      </div>
      <div className="actions">
        <a
          id="whats-next-go-to-web"
          className="go-to-web"
          href={links.web}
          target="_blank"
          onClick={() => CampaignManager.trackStartTrial("web")}
        >
          <img src={btn_web_nicknack} height="40px" alt="Go to NickNack Web" />
        </a>
        <a
          id="whats-next-install-android"
          className="install-android"
          href={links.installAndroid}
          target="_blank"
          onClick={() => CampaignManager.trackStartTrial("android")}
        >
          <img src={playStore} height="40px" alt="Get it on Google Play" />
        </a>
        <a
          id="whats-next-install-ios"
          className="install-ios"
          href={links.installIOS}
          target="_blank"
          onClick={() => CampaignManager.trackStartTrial("ios")}
        >
          <img src={appStore} height="40px" alt="Download on the App Store" />
        </a>
      </div>
    </EpilogueArticleWrapper>
  );
};

export default EpilogueArticle;
