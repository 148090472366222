import useWindowSize from "react-use/lib/useWindowSize";
import { useParams, useLocation } from "react-router-dom";
import Confetti from "react-confetti";
import StepLayout from "./StepLayout";
import links from "../services/links";
import styled from "@emotion/styled";
import useToggle from "../hooks/useToggle";
import Sharer from "./Sharer";
import { useAuth } from "./AuthProvider";

const CopySection = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    display: flex;
    cursor: pointer;
    align-items: center;
    background-color: #e6d7f1;
    padding: 6px;
    border-radius: 50%;

    &:hover {
      background-color: #d1bce0;
    }
  }

  p {
    font-size: 14px;
  }

  svg {
    padding-right: 6px;
  }
`;

const SignUpStep3 = () => {
  const { width, height } = useWindowSize();
  const auth = useAuth();
  const params: any = useParams();
  const location = useLocation();
  const invite_link = location.state && (location.state as any).invite_link;
  const sharer = useToggle();

  const shareTitle = `Join your team "${params.name}" on NickNack`,
    shareText = `${auth.displayName} is inviting you to join the fun with all of "${params.name}" on NickNack`,
    invitiationText = `I'm inviting you to join the fun with all of "${params.name}" on NickNack\n\n` + invite_link;

  return (
    <StepLayout>
      <Confetti width={width} height={height} numberOfPieces={100} />
      <h1 style={{ color: "var(--primary-color)" }}>{params.name}</h1>
      <h1>
        is now an
        <br />
        official NickNack team
      </h1>
      <input
        id="step3-open-app"
        type="submit"
        value="Go try it now!"
        onClick={() => {
          window.location.href = links.app_reg_complete;
        }}
      />
      <br />
      {invite_link && (
        <CopySection>
          <div
            onClick={e => {
              e.stopPropagation();
              if (navigator.share) {
                navigator.share({ text: shareText, title: shareTitle, url: invite_link }).catch(e => console.error(e));
              } else {
                sharer.handleOpen();
              }
            }}
          >
            <svg style={{ width: "48px", height: "48px" }} viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M18,16.08C17.24,16.08 16.56,16.38 16.04,16.85L8.91,12.7C8.96,12.47 9,12.24 9,12C9,11.76 8.96,11.53 8.91,11.3L15.96,7.19C16.5,7.69 17.21,8 18,8A3,3 0 0,0 21,5A3,3 0 0,0 18,2A3,3 0 0,0 15,5C15,5.24 15.04,5.47 15.09,5.7L8.04,9.81C7.5,9.31 6.79,9 6,9A3,3 0 0,0 3,12A3,3 0 0,0 6,15C6.79,15 7.5,14.69 8.04,14.19L15.16,18.34C15.11,18.55 15.08,18.77 15.08,19C15.08,20.61 16.39,21.91 18,21.91C19.61,21.91 20.92,20.61 20.92,19A2.92,2.92 0 0,0 18,16.08Z"
              />
            </svg>
          </div>
          <p>
            Here is an invite link you can share
            <br />
            to invite your peers/friends
          </p>
        </CopySection>
      )}
      {invite_link && (
        <Sharer
          invitiationText={invitiationText}
          title={shareTitle}
          text={shareText}
          shareUrl={invite_link}
          onClose={sharer.handleClose}
          open={sharer.open}
        />
      )}
    </StepLayout>
  );
};

export default SignUpStep3;
