import styled from "@emotion/styled";
import { FormEventHandler, useState } from "react";
import CampaignManager from "../services/CampaignManager";

const ContactUsArticleWrapper = styled.article`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 480px;
  h1 {
    margin-top: 60px;
    margin-bottom: 0;
    font-size: 43px;
    font-weight: 400;
  }
  > a {
    color: var(--text-color);
    text-decoration: none;
  }
  p {
    font: 16px "Open Sans", sans-serif;
    margin: 2rem;
    line-height: 28px;
  }

  form {
    width: 90%;
    @media (min-width: 1024px) {
      width: initial;
    }
    label {
      font-size: 14px;
    }
    > div {
      display: flex;
      flex-direction: column;
      gap: 1vw;

      & > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 1vw;
        @media (min-width: 1024px) {
          flex-direction: row;
        }
      }
      & > div > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
      }
    }
  }
  .error {
    margin: 10px;
    color: red;
  }
`;

const ContactUsArticle = () => {
  const [form, setForm] = useState({ full_name: "", company: "", role: "", email: "", message: "" });
  const [sent, setSent] = useState<boolean | string>(false);
  const [sending, setSending] = useState(false);
  const [error, setError] = useState<string>("");

  const sendMessage: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    e.stopPropagation();
    setSending(true);
    fetch(CampaignManager.forwardQuery(window.location.origin + "/api/send-to-inbox").href, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    })
      .then(res => res.json())
      .then(({ success, error }: { success: boolean; error: string | null }) => {
        if (success) {
          setSent(true);
        } else {
          setSent(error || "Server Error");
        }
        setSending(false);
      })
      .catch((e: Error) => {
        setError(e.message);
        setSending(false);
      });
  };

  if (sent) {
    return (
      <ContactUsArticleWrapper>
        <h1>Contact us</h1>
        <p>Thank you! we will contact you shortly</p>
      </ContactUsArticleWrapper>
    );
  }

  return (
    <ContactUsArticleWrapper>
      <a id="contact-us" href="#contact-us">
        <h1>Contact us</h1>
      </a>
      <p>We'de love to hear from you! Leave us a note and we'll get back to you shortly</p>
      <form id="contact-us-form" onSubmit={sendMessage}>
        <div>
          <div>
            <div>
              <label>Full name*</label>
              <input
                required
                disabled={sending}
                type="text"
                placeholder="Full name"
                value={form.full_name}
                onChange={e => setForm(form => ({ ...form, full_name: e.target.value }))}
              />
            </div>
            <div>
              <label>Email*</label>
              <input
                required
                disabled={sending}
                type="email"
                placeholder="Email"
                value={form.email}
                onChange={e => setForm(form => ({ ...form, email: e.target.value }))}
              />
            </div>
            <div>
              <label>Role</label>
              <input
                disabled={sending}
                type="text"
                placeholder="Role"
                value={form.role}
                onChange={e => setForm(form => ({ ...form, role: e.target.value }))}
              />
            </div>
            <div>
              <label>Company</label>
              <input
                disabled={sending}
                type="text"
                placeholder="Company"
                value={form.company}
                onChange={e => setForm(form => ({ ...form, company: e.target.value }))}
              />
            </div>
          </div>
          <div>
            <div>
              <label>Message*</label>
              <textarea
                disabled={sending}
                required
                placeholder="Message"
                rows={5}
                value={form.message}
                onChange={e => setForm(form => ({ ...form, message: e.target.value || "" }))}
              />
            </div>
          </div>
        </div>
        <input type="submit" id="contact-us-submit" disabled={sending} value="Send it!" />
        {error && <div className="error">Error: {error}</div>}
      </form>
    </ContactUsArticleWrapper>
  );
};

export default ContactUsArticle;
