/* eslint "react/jsx-no-target-blank": "off" -- We want to pass analytics data forward. */
import styled from "@emotion/styled";
import step_1 from "../assets/ass2/step_1.png";
import step_2 from "../assets/ass2/step_2.png";
import step_3 from "../assets/ass2/step_3.png";
import ic_num_1 from "../assets/land2/ic_num_1.png";
import ic_num_2 from "../assets/land2/ic_num_2.png";
import ic_num_3 from "../assets/land2/ic_num_3.png";
import step_arrow from "../assets/ass2/step_arrow.png";
import btn_add_to_teams from "../assets/btn-add-to-teams.svg";
import btn_add_to_slack from "../assets/btn-add-to-slack.svg";
import links from "../services/links";
import CampaignManager from "../services/CampaignManager";

const HowItWorksWrapper = styled.div`
  background-color: var(--bg-grey);
  text-align: center;
  padding: 4vw 2vw;

  h1 {
    margin: 0 0 2em;
    font-size: 28px;
    font-weight: 400;
    @media (min-width: 1024px) {
      margin: 0 0 0.5em;
      font-size: 43px;
    }
  }
  h4 {
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    color: var(--secondary-color);
    @media (min-width: 1024px) {
      font-size: 28px;
      line-height: 36px;
    }
  }
  section {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
    padding: 0 2rem;
    @media (min-width: 1024px) {
      gap: 40px;
      align-items: flex-start;
      padding: 2rem;
      flex-direction: row;
    }
    > img {
      transform: rotate(90deg);
      @media (min-width: 1024px) {
        display: initial;
        transform: none;
      }
    }
    article {
      flex-basis: 33%;
      text-align: center;
      > div {
        font: 16px "Open Sans", sans-serif;
        line-height: 20px;
        height: 5em;
        @media (min-width: 1024px) {
          line-height: 28px;
        }
        color: #1d1c1c;
      }
    }
  }
  figure[data-img] {
    width: 90%;
    height: 67px;
    @media (min-width: 1024px) {
      width: 18vw;
      height: 4vw;
    }
    margin: 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }
  figure[data-img="1"] {
    background-image: url(${step_1});
  }
  figure[data-img="2"] {
    background-image: url(${step_2});
  }
  figure[data-img="3"] {
    background-image: url(${step_3});
  }
  .actions {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  &[data-variant="short"] {
    padding: 2vw;
    h1 {
      margin: 0.5em;
    }
    section {
      align-items: center;
      gap: 16px;
      padding: 2vw;
      article {
        display: flex;
        align-items: center;
        width: 100%;
        > div {
          text-align: left;
          flex: 0.8;
          display: flex;
          justify-content: center;
          align-items: center;
          @media (min-width: 1024px) {
            flex: 0.9;
          }
        }
      }
    }
    figure[data-img] {
      width: 60px;
      height: 60px;
    }
    figure[data-img="1"] {
      background-image: url(${ic_num_1});
    }
    figure[data-img="2"] {
      background-image: url(${ic_num_2});
    }
    figure[data-img="3"] {
      background-image: url(${ic_num_3});
    }
  }
`;

const HowItWorksArticle = ({ variant }: { variant?: string }) => {
  return (
    <HowItWorksWrapper data-variant={variant}>
      <h1>How it works</h1>
      <section>
        <article>
          <figure data-img="1" />
          <div>
            <span>
              Add NickNack to
              <br />
              <strong>Microsoft Teams or Slack</strong>
            </span>
            {!variant && (
              <div className="actions">
                <a
                  id="how-it-works-add-to-teams"
                  className="add-to-teams"
                  href={links.installTeams}
                  target="_blank"
                  onClick={() => CampaignManager.trackStartTrial("msteams")}
                >
                  <img src={btn_add_to_teams} height="40px" alt="Add to Teams" />
                </a>
                <a
                  id="how-it-works-add-to-slack"
                  className="add-to-slack"
                  href={links.installSlack}
                  target="_blank"
                  onClick={() => CampaignManager.trackStartTrial("slack")}
                >
                  <img src={btn_add_to_slack} height="40px" alt="Add to Slack" />
                </a>
              </div>
            )}
          </div>
        </article>
        <img src={step_arrow} alt="arrow" />
        <article>
          <figure data-img="2" />
          <div>
            <span>
              <strong>Start getting suggestions</strong> for team challenges and activities
            </span>
          </div>
        </article>
        <img src={step_arrow} alt="arrow" />
        <article>
          <figure data-img="3" />
          <div>
            <span>
              That’s it! <strong>NickNack will send fun, personalized prompts</strong> - you don’t have to worry about
              anything
            </span>
          </div>
        </article>
      </section>
    </HowItWorksWrapper>
  );
};

export default HowItWorksArticle;
