import video_bg from "../assets/video_bg.jpg";
import { MouseEventHandler, useRef, useState } from "react";

const MarketingVideo = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [playing, setPlaying] = useState(false);
  const play: MouseEventHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    videoRef.current?.play();
    setPlaying(true);
  };
  const pause: MouseEventHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    videoRef.current?.pause();
    setPlaying(false);
  };

  return (
    <aside>
      {!playing && <button id="video-play" onClick={play} />}
      <video className="video" ref={videoRef} onClick={pause} poster={video_bg} controls={playing}>
        <source
          src="https://firebasestorage.googleapis.com/v0/b/nick-nack.appspot.com/o/www%2FNickNack_SFX_Final_subs_2.mp4?alt=media&token=bce5ab8c-b1e3-410b-934b-dfd0d2db5d70"
          type="video/mp4"
        />
      </video>
    </aside>
  );
};

export default MarketingVideo;
