/* eslint "react/jsx-no-target-blank": "off" -- We want to pass analytics data forward. */
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import btn_play_l from "../../../assets/btn_play_l.png";
import btn_play_l_hover from "../../../assets/btn_play_l_hover.png";
import btn_add_to_teams from "../../../assets/btn-add-to-teams.svg";
import btn_add_to_slack from "../../../assets/btn-add-to-slack.svg";
import links from "../../../services/links";
import CampaignManager from "../../../services/CampaignManager";

import logo_with_underline from "../../../assets/land3/logo_with_underline.png";
import ic_checkmark from "../../../assets/land3/ic_checkmark.png";
import ic_cross from "../../../assets/land3/ic_cross.png";
import arrow_curve from "../../../assets/land3/arrow_curve.png";
import statistics_divider from "../../../assets/ass2/statistics_divider.png";
import TestimonialsArticle from "../../TestimonialsArticle";
import MarketingVideo from "../../MarketingVideo";

const TopCoverWrapper = styled.section`
  min-height: 566px;
  background: linear-gradient(to bottom, var(--gradient-1), #5574c7 97%);
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 40px;
  gap: 2vw;
  @media (min-width: 1024px) {
    padding: 48px;
    gap: 6vw;
  }

  h1 {
    font-size: 30px;
    line-height: 38px;
    font-weight: 600;
    margin: 0 0 30px;
    @media (min-width: 1024px) {
      font-size: 38px;
      line-height: 48px;
    }
  }

  p {
    font: 18px "Open Sans", sans-serif;
    line-height: 30px;
    @media (min-width: 1024px) {
      margin: 0 0 40px;
    }
  }

  strong {
    font-weight: 600;
  }

  > div {
    flex: 1 1 46%;
  }

  .actions {
    display: flex;
    gap: 8px;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;

    img {
      margin-right: 10px;
    }
  }

  aside {
    flex: 1 1 46%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 300px;
    height: 300px;
    position: relative;

    div {
      position: absolute;
      opacity: 0.5;
      background: black;

      &[data-playing] {
        opacity: 0;
      }
    }

    button {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      transform: translate(-50%, -50%);
      width: 108px;
      height: 108px;
      border: none;
      background: transparent url(${btn_play_l}) no-repeat;
      background-size: cover;

      &:hover {
        background: url(${btn_play_l_hover}) no-repeat;
        cursor: pointer;
      }
    }
  }

  video {
    max-width: 100%;
    @media (min-width: 1024px) {
      margin-top: 80px;
    }
  }

  section {
    display: flex;
    flex-direction: column;
    @media (min-width: 1024px) {
      flex-direction: row;
    }

    > article {
      flex: 0.45;
    }

    > article.arrow_curve {
      flex: 0.1;
      padding-top: 85px;
      display: none;
      @media (min-width: 1024px) {
        display: block;
      }
    }
  }

  .numbers {
    display: flex;
    justify-content: space-around;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    margin-top: 20px;
    @media (min-width: 1024px) {
      margin-top: 0;
    }

    span div:first-of-type {
      height: 34px;
      font-size: 34px;
      line-height: 34px;
      font-weight: 600;
      margin-bottom: 5px;
      @media (min-width: 1024px) {
        font-size: 56px;
        line-height: 56px;
        height: 56px;
      }
    }

    > div {
      background: url(${statistics_divider}) no-repeat;
      background-size: contain;
      width: 10px;
      height: 100%;
    }

    span div:last-of-type {
      height: 24px;
      font-size: 0.8em;
      @media (min-width: 1024px) {
        font-size: 1em;
      }
    }
  }
`;

const Landing3 = () => {
  return (
    <>
      <TopCoverWrapper>
        <div>
          <Link to="/">
            <img src={logo_with_underline} alt="logo" />
          </Link>
          <h1>Too bad Jira and GitHub don’t boost team morale</h1>
          <p>
            <ul>
              <li>
                <img src={ic_checkmark} alt="checkmark" />
                Fun, effective team activities
              </li>
              <li>
                <img src={ic_cross} alt="cross" />
                No scheduling, nagging or stress
              </li>
            </ul>
          </p>
          <div className="actions">
            {/*<a href="https://web.nicknack.app/?source=www&onboarding=true" target="_blank">*/}
            {/*  <img src={btn_try_for_free} height="40px" alt="Try for free" />*/}
            {/*</a>*/}
            <a
              id="cover-add-to-teams"
              className="add-to-teams"
              href={links.installTeams}
              target="_blank"
              onClick={() => CampaignManager.trackStartTrial("msteams")}
            >
              <img src={btn_add_to_teams} height="40px" alt="Add to Teams" />
            </a>
            <a
              id="cover-add-to-slack"
              className="add-to-slack"
              href={links.installSlack}
              target="_blank"
              onClick={() => CampaignManager.trackStartTrial("slack")}
            >
              <img src={btn_add_to_slack} height="40px" alt="Add to Slack" />
            </a>
          </div>
        </div>
        <MarketingVideo />
        <section>
          <article>
            We take care of your team's energy when you're busy managing their work. With NickNack you can initiate
            non-awkward social interactions that boost morale and give your team members a chance to shine - all from{" "}
            <strong>Microsoft Teams</strong> or <strong>Slack</strong>.
          </article>
          <article className="arrow_curve">
            <img src={arrow_curve} alt="arrow" />
          </article>
          <article className="numbers">
            <span>
              <div>7,500+</div>
              <div>Challenges played</div>
            </span>
            <div />
            <span>
              <div>1000+</div>
              <div>Users around the world!</div>
            </span>
          </article>
        </section>
      </TopCoverWrapper>
      <TestimonialsArticle variant="qa" />
    </>
  );
};

export default Landing3;
