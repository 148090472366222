import styled from "@emotion/styled";

import img_ceo_1 from "../assets/skins/img_ceo.png";
import img_ceo_2 from "../assets/skins/img_ceo-2.png";
import img_ceo_3 from "../assets/skins/img_ceo-3.png";
import img_hr_1 from "../assets/skins/img_hr.png";
import img_hr_2 from "../assets/skins/img_hr-2.png";
import img_hr_3 from "../assets/skins/img_hr-3.png";
import img_team_manager_1 from "../assets/skins/img_team_manager.png";
import img_team_manager_2 from "../assets/skins/img_team_manager-2.png";
import img_team_manager_3 from "../assets/skins/img_team_manager-3.png";
import img_team_member_1 from "../assets/skins/img_team_member.png";
import img_team_member_2 from "../assets/skins/img_team_member-2.png";
import img_team_member_3 from "../assets/skins/img_team_member-3.png";
import { useMemo } from "react";

const skins = [1, 2, 3, Math.floor(Math.random() * 3) + 1]; // 1 to 3 and 1 random
function shuffle(array: any[]) {
  let currentIndex = array.length,
    randomIndex;
  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }
  return array;
}

const SocialProofWrapper = styled.article`
  background-color: white;
  text-align: center;
  padding: 4vw 2vw;

  h1 {
    margin: 0 0 2em;
    font-size: 28px;
    font-weight: 400;
    @media (min-width: 1024px) {
      margin: 0 0 0.5em;
      font-size: 43px;
    }
  }
  h4 {
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    color: var(--secondary-color);
    @media (min-width: 1024px) {
      font-size: 28px;
      line-height: 36px;
    }
  }
  section {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    padding: 0 2rem;

    @media (min-width: 1024px) {
      gap: 1vw;
      padding: 2rem;
      flex-direction: row;
    }
    article {
      flex-basis: 33%;
      text-align: center;
    }
    p {
      font: 16px "Open Sans", sans-serif;
      line-height: 20px;
      @media (min-width: 1024px) {
        line-height: 28px;
      }
      color: #1d1c1c;
    }
  }
  figure[data-img] {
    width: 90%;
    height: 200px;
    @media (min-width: 1024px) {
      width: 18vw;
      height: 14vw;
    }
    margin: 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }
  figure[data-img="1-1"] {
    background-image: url(${img_ceo_1});
    max-width: 224px;
  }
  figure[data-img="1-2"] {
    background-image: url(${img_ceo_2});
    max-width: 224px;
  }
  figure[data-img="1-3"] {
    background-image: url(${img_ceo_3});
    max-width: 224px;
  }
  figure[data-img="2-1"] {
    background-image: url(${img_hr_1});
    max-width: 220px;
  }
  figure[data-img="2-2"] {
    background-image: url(${img_hr_2});
    max-width: 220px;
  }
  figure[data-img="2-3"] {
    background-image: url(${img_hr_3});
    max-width: 220px;
  }
  figure[data-img="3-1"] {
    background-image: url(${img_team_manager_1});
    max-width: 270px;
  }
  figure[data-img="3-2"] {
    background-image: url(${img_team_manager_2});
    max-width: 270px;
  }
  figure[data-img="3-3"] {
    background-image: url(${img_team_manager_3});
    max-width: 270px;
  }
  figure[data-img="4-1"] {
    background-image: url(${img_team_member_1});
    max-width: 190px;
  }
  figure[data-img="4-2"] {
    background-image: url(${img_team_member_2});
    max-width: 190px;
  }
  figure[data-img="4-3"] {
    background-image: url(${img_team_member_3});
    max-width: 190px;
  }
`;

const SocialProofArticle = () => {
  const randPeople = useMemo(() => shuffle([1, 2, 3, 4]), []);
  const randSkin = useMemo(() => shuffle(skins), []);

  return (
    <SocialProofWrapper>
      <h1>How can NickNack help you?</h1>
      <section>
        <article>
          <figure data-img={`${randPeople[0]}-${randSkin[0]}`} />
          <h4>CEO</h4>
          <p>
            Create a connected and scalable organization. NickNack gives your leaders the tools they need to manage
            remotely.
          </p>
        </article>
        <article>
          <figure data-img={`${randPeople[1]}-${randSkin[1]}`} />
          <h4>HR</h4>
          <p>
            Empower managers to invest in team engagement. No maintenance or extra budget needed - NickNack is on it.
          </p>
        </article>
        <article>
          <figure data-img={`${randPeople[2]}-${randSkin[2]}`} />
          <h4>Team Manager</h4>
          <p>
            Motivate and mobilize your team while focusing on your work. NickNack will take care of everything else.
          </p>
        </article>
        <article>
          <figure data-img={`${randPeople[3]}-${randSkin[3]}`} />
          <h4>Team Member</h4>
          <p>Feel more connected to your coworkers, without the drag of remote happy hours.</p>
        </article>
      </section>
    </SocialProofWrapper>
  );
};

export default SocialProofArticle;
