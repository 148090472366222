import StepLayout from "./StepLayout";
import links from "../services/links";
import { signOut } from "./AuthProvider";
import { useHistory } from "react-router-dom";

const SignUpRegistered = () => {
  const history = useHistory();

  return (
    <StepLayout>
      <h2>
        Seems like you
        <br />
        are already part of an existing team
      </h2>
      <br />
      <a
        href="#different"
        style={{ color: "var(--primary-color)", textDecoration: "none" }}
        onClick={() => {
          signOut(true).then(() => {
            history.push("/signup");
          });
        }}
      >
        Use a different account
      </a>
      <br />
      <input
        id="signup-registered-open-app"
        type="submit"
        value="Open App"
        onClick={() => {
          window.location.href = links.app;
        }}
      />
    </StepLayout>
  );
};

export default SignUpRegistered;
