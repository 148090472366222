import TopCover from "./TopCover";
import NumbersArticle from "./NumbersArticle";
import TestimonialsArticle from "./TestimonialsArticle";
import FeaturesArticle from "./FeaturesArticle";
import EpilogueArticle from "./EpilogueArticle";
import Header from "./Header";
import React from "react";
import SocialProofArticle from "./SocialProofArticle";
import ContactUsArticle from "./ContactUsArticle";
import HowItWorksArticle from "./HowItWorksArticle";
import ReadAboutUsArticle from "./ReadAboutUsArticle";
import LogoCarousel from "./LogoCarousel";
import Announcement from "./Announcement";
import announcementUrl from "./announcementUrl";

const Homepage = () => {
  return (
    <>
      {announcementUrl && <Announcement url={announcementUrl}/>}
      <TopCover />
      <LogoCarousel />
      <NumbersArticle />
      <SocialProofArticle />
      <FeaturesArticle />
      <TestimonialsArticle />
      {/*<HowItWorksArticle />*/}
      <ReadAboutUsArticle />
      {/*<EpilogueArticle />*/}
      <ContactUsArticle />
      <Header footer />
    </>
  );
};

export default Homepage;
