import styled from "@emotion/styled";
import statistics_divider from "../assets/ass2/statistics_divider.png";

const InNumbersWrapper = styled.article`
  color: #ffffff;
  background-color: #3c6abf;
  height: 100px;
  @media (min-width: 1024px) {
    height: 140px;
  }
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    margin: 28px;
    font-size: 28px;
    font-weight: 400;
    @media (min-width: 1024px) {
      margin: 56px;
      font-size: 43px;
    }
  }
  > div {
    background: url(${statistics_divider}) no-repeat;
    background-size: contain;
    width: 10px;
    height: 100%;
  }
  section {
    flex: 0.3;
    @media (min-width: 1024px) {
      flex: 0.2;
    }
    text-align: center;
    font-family: "Open Sans", sans-serif;

    div:first-of-type {
      height: 34px;
      font-size: 34px;
      line-height: 34px;
      font-weight: 600;
      margin-bottom: 5px;
      @media (min-width: 1024px) {
        font-size: 56px;
        line-height: 56px;
        height: 56px;
      }
    }
    div:last-of-type {
      height: 24px;
      font-size: 0.8em;
      @media (min-width: 1024px) {
        font-size: 1em;
      }
    }
  }
`;

const NumbersArticle = () => {
  return (
    <InNumbersWrapper>
      <section>
        <div>7,500+</div>
        <div>Challenges played</div>
      </section>
      <div />
      <section>
        <div>548+</div>
        <div>Companies</div>
      </section>
      <div />
      <section>
        <div>30+</div>
        <div>Activities</div>
      </section>
    </InNumbersWrapper>
  );
};

export default NumbersArticle;
