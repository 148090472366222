/* eslint "react/jsx-no-target-blank": "off" -- We want to pass analytics data forward. */
import styled from "@emotion/styled";
import btn_play_l from "../assets/btn_play_l.png";
import btn_play_l_hover from "../assets/btn_play_l_hover.png";
import btn_add_to_teams from "../assets/btn-add-to-teams.svg";
import btn_add_to_slack from "../assets/btn-add-to-slack.svg";
import links from "../services/links";
import CampaignManager from "../services/CampaignManager";
import MarketingVideo from "./MarketingVideo";

const TopCoverWrapper = styled.section`
  min-height: 566px;
  background: linear-gradient(to bottom, var(--gradient-1), var(--gradient-2));
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 8vw 4vw;
  @media (min-width: 1024px) {
    padding: 8vw 6vw;
    gap: 6vw;
  }
  h1 {
    font-size: 30px;
    line-height: 38px;
    font-weight: 600;
    margin: 0 0 30px;
    @media (min-width: 1024px) {
      font-size: 38px;
      line-height: 48px;
    }
  }

  p {
    font: 18px "Open Sans", sans-serif;
    line-height: 30px;
    @media (min-width: 1024px) {
      margin: 0 0 40px;
    }
  }

  strong {
    font-weight: 600;
  }

  > div {
    flex: 1 1 46%;
  }

  .actions {
    display: flex;
    gap: 8px;
  }

  aside {
    flex: 1 1 46%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 300px;
    height: 300px;
    position: relative;
    div {
      position: absolute;
      opacity: 0.5;
      background: black;
      &[data-playing] {
        opacity: 0;
      }
    }
    button {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      transform: translate(-50%, -50%);
      width: 108px;
      height: 108px;
      border: none;
      background: transparent url(${btn_play_l}) no-repeat;
      background-size: cover;
      &:hover {
        background: url(${btn_play_l_hover}) no-repeat;
        cursor: pointer;
      }
    }
  }
  video {
    max-width: 100%;
  }
`;

const TopCover = () => {
  return (
    <TopCoverWrapper>
      <div>
        <h1>Recreating your office chemistry - from anywhere</h1>
        <p>
          NickNack’s helps fill the gaps of teams spread apart with asynchronous activities to foster team togetherness
          on your own terms
        </p>
        <div className="actions">
          {/*<a href="https://web.nicknack.app/?source=www&onboarding=true" target="_blank">*/}
          {/*  <img src={btn_try_for_free} height="40px" alt="Try for free" />*/}
          {/*</a>*/}
          {/*<a*/}
          {/*  id="cover-add-to-teams"*/}
          {/*  className="add-to-teams"*/}
          {/*  href={links.installTeams}*/}
          {/*  target="_blank"*/}
          {/*  onClick={() => CampaignManager.trackStartTrial("msteams")}*/}
          {/*>*/}
          {/*  <img src={btn_add_to_teams} height="40px" alt="Add to Teams" />*/}
          {/*</a>*/}
          {/*<a*/}
          {/*  id="cover-add-to-slack"*/}
          {/*  className="add-to-slack"*/}
          {/*  href={links.installSlack}*/}
          {/*  target="_blank"*/}
          {/*  onClick={() => CampaignManager.trackStartTrial("slack")}*/}
          {/*>*/}
          {/*  <img src={btn_add_to_slack} height="40px" alt="Add to Slack" />*/}
          {/*</a>*/}
        </div>
      </div>
      <MarketingVideo />
    </TopCoverWrapper>
  );
};

export default TopCover;
