/* eslint "react/jsx-no-target-blank": "off" -- We want to pass analytics data forward. */
import styled from "@emotion/styled";
import btn_play_l from "../../../assets/btn_play_l.png";
import btn_play_l_hover from "../../../assets/btn_play_l_hover.png";
import btn_add_to_teams from "../../../assets/btn-add-to-teams.svg";
import btn_add_to_slack from "../../../assets/btn-add-to-slack.svg";
import links from "../../../services/links";
import CampaignManager from "../../../services/CampaignManager";

import ic_checkmark from "../../../assets/land3/ic_checkmark.png";
import ic_cross from "../../../assets/land3/ic_cross.png";
import statistics_divider from "../../../assets/ass2/statistics_divider.png";
import Header from "../../Header";
import MarketingVideo from "../../MarketingVideo";

const TopCoverWrapper = styled.section`
  min-height: 566px;
  background: linear-gradient(to bottom, var(--gradient-1), var(--gradient-2));
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 40px;
  gap: 2vw;
  @media (min-width: 1024px) {
    padding: 80px 48px 40px;
    gap: 6vw;
  }

  h1 {
    font-size: 30px;
    line-height: 38px;
    font-weight: 600;
    margin: 30px 0 30px;
    @media (min-width: 1024px) {
      font-size: 38px;
      line-height: 48px;
    }
  }

  p {
    font: 18px "Open Sans", sans-serif;
    line-height: 30px;
    @media (min-width: 1024px) {
      margin: 0 0 40px;
    }
  }

  strong {
    font-weight: 600;
  }

  > div {
    flex: 1 1 46%;
  }

  .actions {
    display: flex;
    gap: 8px;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;

    img {
      margin-right: 10px;
    }
  }

  aside {
    flex: 1 1 46%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 300px;
    height: 300px;
    position: relative;
    margin-top: 64px;

    div {
      position: absolute;
      opacity: 0.5;
      background: black;

      &[data-playing] {
        opacity: 0;
      }
    }

    button {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      transform: translate(-50%, -50%);
      width: 108px;
      height: 108px;
      border: none;
      background: transparent url(${btn_play_l}) no-repeat;
      background-size: cover;

      &:hover {
        background: url(${btn_play_l_hover}) no-repeat;
        cursor: pointer;
      }
    }
  }

  video {
    max-width: 100%;
    @media (min-width: 1024px) {
      margin-top: 80px;
      max-height: 360px;
    }
  }

  .numbers {
    display: flex;
    width: 100%;
    justify-content: space-around;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    margin-top: 20px;
    @media (min-width: 1024px) {
      margin-top: 0;
    }

    span div:first-of-type {
      height: 34px;
      font-size: 34px;
      line-height: 34px;
      font-weight: 600;
      margin-bottom: 5px;
      @media (min-width: 1024px) {
        font-size: 56px;
        line-height: 56px;
        height: 56px;
      }
    }

    > div {
      background: url(${statistics_divider}) no-repeat;
      background-size: contain;
      width: 10px;
      height: 85px;
    }

    span div:last-of-type {
      height: 24px;
      font-size: 0.8em;
      @media (min-width: 1024px) {
        font-size: 1em;
      }
    }
  }
`;

const CoverWithHeader = () => {
  return (
    <TopCoverWrapper>
      <Header variant="transparent" />
      <div>
        <h1>Too bad Jira and GitHub don’t boost team morale</h1>
        <p>
          <ul>
            <li>
              <img src={ic_checkmark} alt="checkmark" />
              Fun, effective team activities
            </li>
            <li>
              <img src={ic_cross} alt="cross" />
              No scheduling, nagging or stress
            </li>
          </ul>
        </p>
        <div className="actions">
          {/*<a href="https://web.nicknack.app/?source=www&onboarding=true" target="_blank">*/}
          {/*  <img src={btn_try_for_free} height="40px" alt="Try for free" />*/}
          {/*</a>*/}
          <a
            id="cover-add-to-teams"
            className="add-to-teams"
            href={links.installTeams}
            target="_blank"
            onClick={() => CampaignManager.trackStartTrial("msteams")}
          >
            <img src={btn_add_to_teams} height="40px" alt="Add to Teams" />
          </a>
          <a
            id="cover-add-to-slack"
            className="add-to-slack"
            href={links.installSlack}
            target="_blank"
            onClick={() => CampaignManager.trackStartTrial("slack")}
          >
            <img src={btn_add_to_slack} height="40px" alt="Add to Slack" />
          </a>
        </div>
      </div>
      <MarketingVideo />
      <article className="numbers">
        <span>
          <div>7,500+</div>
          <div>Challenges played</div>
        </span>
        <div />
        <span>
          <div>1000+</div>
          <div>Users around the world!</div>
        </span>
      </article>
    </TopCoverWrapper>
  );
};

export default CoverWithHeader;
