import Drawer from "react-bottom-drawer";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
  ViberShareButton,
  WorkplaceShareButton,
  LineShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
} from "react-share";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 240px));
  gap: 32px;
  padding-bottom: 32px;
  > div {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  svg {
    border-radius: 4px;
  }
`;

const Sharer = ({
  invitiationText,
  title,
  text,
  shareUrl,
  open,
  onClose,
}: {
  invitiationText: string;
  title: string;
  text: string;
  shareUrl: string;
  open: boolean;
  onClose: () => void;
}) => {
  const [copied, setCopied] = useState(false);
  const [copiedInv, setCopiedInv] = useState(false);

  useEffect(() => {
    if (open) {
      setCopied(false);
      setCopiedInv(false);
    }
  }, [open]);

  return (
    <Drawer duration={250} hideScrollbars={true} onClose={onClose} isVisible={open}>
      <h3>Share via</h3>
      <Container>
        <div>
          <div
            onClick={e => {
              e.stopPropagation();
              navigator.clipboard.writeText(invitiationText).then(() => {
                setCopiedInv(true);
                setCopied(false);
              });
            }}
          >
            {copiedInv ? (
              <svg style={{ width: "64px", height: "64px" }} viewBox="0 0 24 24">
                <path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
              </svg>
            ) : (
              <svg style={{ width: "64px", height: "64px" }} viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"
                />
              </svg>
            )}
          </div>
          {copiedInv ? "Copied" : "Copy Invitation"}
        </div>

        <div>
          <div
            onClick={e => {
              e.stopPropagation();
              navigator.clipboard.writeText(shareUrl).then(() => {
                setCopied(true);
                setCopiedInv(false);
              });
            }}
          >
            {copied ? (
              <svg style={{ width: "64px", height: "64px" }} viewBox="0 0 24 24">
                <path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
              </svg>
            ) : (
              <svg style={{ width: "64px", height: "64px" }} viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"
                />
              </svg>
            )}
          </div>
          {copied ? "Copied" : "Copy Link"}
        </div>
        <div>
          <div
            onClick={() => {
              const url = new URL("https://teams.microsoft.com/share");
              url.searchParams.set("href", shareUrl);
              url.searchParams.set("preview", "true");
              url.searchParams.set("msgText", (title + " - " + text + " ").substring(0, 200));
              url.searchParams.set("referrer", window.location.hostname);
              window.open(url.href, "ms-teams-share-popup", "width=700,height=600");
            }}
          >
            <svg width="65" height="64" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="4 2 32 32">
              <g filter="url(#filter0_d)">
                <path d="M4.16 4a2 2 0 012-2h28a2 2 0 012 2v28a2 2 0 01-2 2h-28a2 2 0 01-2-2V4z" fill="#6264A7" />
              </g>
              <path
                d="M27.083 14.65c.414 0 .818-.124 1.162-.357.345-.233.613-.564.771-.951a2.146 2.146 0 00-.453-2.31 2.067 2.067 0 00-2.28-.46c-.383.16-.71.432-.94.78a2.14 2.14 0 00.26 2.678c.393.397.925.62 1.48.62zM20.19 22.476v-8.87a.643.643 0 00-.184-.452.626.626 0 00-.446-.187h-8.77a.626.626 0 00-.445.187.643.643 0 00-.185.452v8.87c0 .171.066.335.184.458a.643.643 0 00.447.197h8.769a.643.643 0 00.447-.197.66.66 0 00.184-.458zm-2.507-7.42v1.372h-1.692v4.77H14.36v-4.677h-1.692v-1.559h5.015v.094z"
                fill="#fff"
              />
              <path
                d="M21.498 13.809v.655c.587-.183 1.1-.551 1.465-1.05a2.94 2.94 0 00.566-1.725c0-.786-.308-1.54-.856-2.095a2.904 2.904 0 00-2.067-.867c-.775 0-1.519.312-2.067.867a2.982 2.982 0 00-.856 2.095h1.708a2.14 2.14 0 011.472.633c.392.395.62.927.635 1.487zM24.683 15.929h-3.185v6.345c0 .57-.22 1.118-.614 1.526a2.14 2.14 0 01-1.493.656h-3.277a5.034 5.034 0 002.331 2.37 4.94 4.94 0 003.281.379 4.992 4.992 0 002.8-1.775 5.109 5.109 0 001.096-3.156V16.88a.96.96 0 00-.275-.672.938.938 0 00-.664-.279zM29.283 15.929h-2.57c.147.295.226.62.232.95v5.395a6.495 6.495 0 01-.37 2.042c.17.015.34.015.508 0 .816 0 1.599-.329 2.176-.913a3.139 3.139 0 00.9-2.205V16.88a.958.958 0 00-.252-.651.933.933 0 00-.624-.3z"
                fill="#fff"
              />
              <defs>
                <filter
                  id="filter0_d"
                  x=".16"
                  y="0"
                  width="40"
                  height="40"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                  <feOffset dy="2" />
                  <feGaussianBlur stdDeviation="2" />
                  <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                  <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
                  <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
              </defs>
            </svg>
          </div>
          Teams
        </div>

        <div>
          <TelegramShareButton url={shareUrl} title={title + " - " + text}>
            <TelegramIcon />
          </TelegramShareButton>
          Telegram
        </div>

        <div>
          <WhatsappShareButton url={shareUrl} title={title + " - " + text} separator=":: ">
            <WhatsappIcon />
          </WhatsappShareButton>
          WhatsApp
        </div>

        <div>
          <LinkedinShareButton url={shareUrl}>
            <LinkedinIcon />
          </LinkedinShareButton>
          LinkedIn
        </div>

        <div>
          <EmailShareButton url={shareUrl} subject={title} body={text + "\n\n"}>
            <EmailIcon />
          </EmailShareButton>
          Email
        </div>

        <div>
          <ViberShareButton url={shareUrl} title={title + " - " + text}>
            <ViberIcon />
          </ViberShareButton>
          Viber
        </div>

        <div>
          <WorkplaceShareButton url={shareUrl} quote={title + " - " + text}>
            <WorkplaceIcon />
          </WorkplaceShareButton>
          Workplace
        </div>

        <div>
          <FacebookShareButton url={shareUrl} quote={title + " - " + text}>
            <FacebookIcon />
          </FacebookShareButton>
          Facebook
        </div>

        <div>
          <TwitterShareButton url={shareUrl} title={title + " - " + text}>
            <TwitterIcon />
          </TwitterShareButton>
          Twitter
        </div>

        <div>
          <LineShareButton url={shareUrl} title={title + " - " + text}>
            <LineIcon round />
          </LineShareButton>
          LINE
        </div>
      </Container>
    </Drawer>
  );
};

export default Sharer;
