import styled from "@emotion/styled";
import img_meet_nicknack from "../assets/land2/img_meet_nicknack.png";

const MeetNickNackWrapper = styled.article`
  text-align: center;
  padding: 0 10vw 2vw;

  h1 {
    font-size: 28px;
    font-weight: 400;
    @media (min-width: 1024px) {
      font-size: 43px;
    }
  }
  > div {
    font: 16px "Open Sans", sans-serif;
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: center;
    @media (min-width: 1024px) {
      flex-direction: row;
    }
    > * {
      flex: 0.5;
      padding: 2vw;
    }
  }
`;

const MeetNickNackArticle = () => {
  return (
    <MeetNickNackWrapper>
      <h1>Meet NickNack</h1>
      <div>
        <img src={img_meet_nicknack} alt="Meet NickNack" />
        <div>
          NickNack takes care of your team's energy when you're busy managing their work. With NickNack you can initiate
          non-awkward social interactions that boost morale and give your team members a chance to shine - all from{" "}
          <strong>Microsoft Teams</strong> or <strong>Slack</strong>.
        </div>
      </div>
    </MeetNickNackWrapper>
  );
};
export default MeetNickNackArticle;
