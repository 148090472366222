const gtmID = "GTM-5QD4FLP";

type WindowWithDataLayer = { dataLayer?: any[] };

class GoogleTagManager {
  static install() {
    const w = window as unknown as WindowWithDataLayer;
    //<!-- Google Tag Manager -->
    w.dataLayer = w.dataLayer || [];
    w.dataLayer.push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
    const scriptElem = document.getElementsByTagName("script")[0],
      j = document.createElement("script");
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + gtmID;
    if (scriptElem && scriptElem.parentNode) {
      scriptElem.parentNode.insertBefore(j, scriptElem);
    } else {
      document.head.appendChild(scriptElem);
    }
    //<!-- End Google Tag Manager -->

    //<!-- Global site tag (gtag.js) - Google Ads: 320623279 -->
  }

  static pushEvent(event = "gtm.click") {
    const w = window as unknown as WindowWithDataLayer;
    if (w.dataLayer) {
      w.dataLayer.push({ event });
    }
  }
}

export default GoogleTagManager;
