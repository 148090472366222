import { CookiesStatic } from "js-cookie";

const keysToStore = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
  "gclid",
  "gclsrc",
  "dclid",
  "fbclid",
  // nicknack
  "_campaign",
];

const getLandingPageCampaign = () => {
  if (!window.location.pathname.startsWith("/lp/")) return null;
  return window.location.pathname.split("/")[2];
};

class CampaignManager {
  static collectCampaignInformation(cookieParams: URLSearchParams) {
    const value = new URLSearchParams();
    const params = new URLSearchParams(window.location.search);
    keysToStore.forEach(key => {
      if (params.has(key)) {
        // this location is more important
        params.getAll(key).forEach(val => value.append(key, val));
      } else if (cookieParams.has(key)) {
        // otherwise fallback on existing ones
        cookieParams.getAll(key).forEach(val => value.append(key, val));
      }
    });
    if (params.has("_source")) {
      value.set("_source", params.get("_source") || "");
    } else if (cookieParams.has("_source")) {
      value.set("_source", cookieParams.get("_source") || "www");
    } else {
      value.set("_source", "www");
    }
    const lp = getLandingPageCampaign();
    if (lp && !value.has("_campaign")) {
      value.set("_campaign", "lp_" + lp);
    }
    value.sort();
    return value.toString();
  }

  static forwardQuery = (url: string): URL => {
    const obj = new URL(url);
    const existing = new URLSearchParams(window.location.search);
    for (const key of keysToStore) {
      if (existing.has(key) && !obj.searchParams.has(key)) obj.searchParams.set(key, existing.get(key) || "");
    }
    if (existing.has("_source")) {
      obj.searchParams.set("_source", existing.get("_source") || "www");
    } else {
      obj.searchParams.set("_source", "www");
    }
    const lp = getLandingPageCampaign();
    if (lp && !obj.searchParams.has("_campaign")) {
      obj.searchParams.set("_campaign", "lp_" + lp);
    }
    return obj;
  };

  static set(Cookies: CookiesStatic) {
    const cookies = Cookies.withConverter({
      read: value => decodeURIComponent(value),
      write: value => encodeURIComponent(value.toString()),
    });

    const existing = cookies.get("nicknack_campaign");
    const existingParams = new URLSearchParams(existing || "");
    const value = CampaignManager.collectCampaignInformation(existingParams);
    cookies.set("nicknack_campaign", value, {
      domain: window.location.hostname === "localhost" ? undefined : "nicknack.app",
      secure: window.location.protocol === "https:",
      sameSite: "Lax",
      expires: 365,
    });
  }

  static trackStartTrial(channel: string) {
    const w = window as any;
    // facebook
    if (w.hasOwnProperty("fbq")) {
      w.fbq("track", "StartTrial", { channel });
    }
    // linkedin
    if (w.hasOwnProperty("lintrk")) {
      w.lintrk("track", { conversion_id: 5504505 });
    }
  }
}

export default CampaignManager;
