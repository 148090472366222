import styled from "@emotion/styled";
import { HashLink } from "react-router-hash-link";
import ic_quote from "../assets/ass2/ic_quote.png";
import bg_faces from "../assets/ass2/bg_faces.jpg";
import bg2_faces from "../assets/land2/bg_faces.jpg";
import bg3_faces from "../assets/land3/bg_faces_gradient.jpg";

const testimonials = [
  {
    quote: () => (
      <p>
        Finally, an easy and fun way for managers to <strong>create team activities</strong> remotely!
      </p>
    ),
    source: "Employee wellbeing manager at Fortune 500 tech company",
  },
  {
    quote: () => (
      <p>
        Such a <strong>smart concept</strong>! As our managers transform to remote work - they're looking for tools like
        NickNack
        <strong>to boost engagement</strong>.
      </p>
    ),
    source: "CEO of a 200-employees tech Unicorn",
  },
];
const year = new Date().getFullYear();

const TestimonialsArticleWrapper = styled.article`
  color: white;
  background: url(${bg_faces}) repeat-x;
  background-size: cover;
  text-align: center;
  padding-top: 6vw;
  position: relative;
  h1 {
    margin: 0;
    font-size: 28px;
    font-weight: 400;
    @media (min-width: 1024px) {
      font-size: 43px;
    }
  }
  section {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 6vh;
    @media (min-width: 1024px) {
      flex-direction: row;
      height: 300px;
      padding-bottom: 0;
    }
  }
  article {
    flex-basis: 40%;
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin: 5vw;
    @media (min-width: 1024px) {
      margin: 0;
    }
    > div {
      text-align: left;
    }
    img {
      margin-top: 0.5em;
    }
    p {
      margin: 0 0 10px;
      font: 16px "Open Sans", sans-serif;
      line-height: 20px;
      @media (min-width: 1024px) {
        font-size: 16px;
        line-height: 28px;
      }
    }
    sub {
      font: 600 12px "Open Sans", sans-serif;
      line-height: 15px;
    }
  }
  > div.footer {
    position: absolute;
    right: 3vw;
    bottom: 3vh;
  }
  a {
    color: white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &[data-variant="qa"] {
    padding-top: 0;
    background: url(${bg3_faces}) repeat-x;
    background-size: cover;
  }
  &[data-variant="short"] {
    padding-top: 50px;
    background: url(${bg2_faces}) repeat-x;
    background-size: cover;
    section {
      padding: 0 7vw;
      @media (min-width: 1024px) {
        height: 200px;
      }
    }
  }
`;

const TestimonialsArticle = ({ variant }: { variant?: string }) => {
  return (
    <TestimonialsArticleWrapper data-variant={variant}>
      <h1>What they’re saying about us</h1>
      <section>
        {testimonials.map(t => (
          <article key={t.source}>
            <img src={ic_quote} alt="quote sign" />
            <div>
              {t.quote()}
              <sub>{t.source}</sub>
            </div>
          </article>
        ))}
      </section>
      {variant === "qa" && (
        <div className="footer">
          <HashLink to="/#contact-us">Contact us</HashLink> | © {year} NickNack
        </div>
      )}
    </TestimonialsArticleWrapper>
  );
};

export default TestimonialsArticle;
