import { useContext, createContext, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import SignUpStep1 from "./SignUpStep1";
import firebase from "../firebase";
import { useHistory } from "react-router-dom";

const firebaseAuth = firebase.auth();
let chooseAccount = false;

export const signOut = async (setChooseAccount = false) => {
  if (setChooseAccount) {
    chooseAccount = true;
  }
  return firebaseAuth.signOut();
};

const AuthContext = createContext<any>(null);

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }: { children: any }) => {
  const [auth, loading, error] = useAuthState(firebaseAuth);
  const history = useHistory();

  useEffect(() => {
    if (auth) {
      history.push("/signup/step-2");
    }
  }, [auth, history]);

  return (
    <AuthContext.Provider value={auth}>
      {loading ? "" : error || !auth ? <SignUpStep1 error={error} chooseAccount={chooseAccount} /> : children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
