import { NavLink } from "react-router-dom";
import Logo from "../assets/logo.png";
import logo2 from "../assets/land2/logo.png";
import ic_menu from "../assets/ic_menu.png";
import ic_menu2 from "../assets/land2/ic_menu.png";
import ic_menu_close from "../assets/ic_menu_close.png";
import styled from "@emotion/styled";
import { useState } from "react";

const HeaderWrapper = styled.header`
  padding: 2.5vw;
  @media (min-width: 1024px) {
    max-height: 120px;
    padding: 2.5vw 8vw;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  position: relative;
  &.footer {
    background-color: var(--bg-grey);
    flex-direction: column;
    @media (min-width: 1024px) {
      flex-direction: row;
    }
    .logo {
      display: none;
      @media (min-width: 1024px) {
        display: initial;
      }
    }
  }

  .logo {
    z-index: 9;
  }
  > a img {
    height: 48px;
    @media (min-width: 1024px) {
      height: 66px;
    }
    &:hover {
      opacity: 0.65;
    }
  }
  .menu {
    background: url(${ic_menu}) no-repeat;
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-top: 14px;
    z-index: 9;
    @media (min-width: 1024px) {
      display: none;
    }
    &[data-open] {
      background: url(${ic_menu_close}) no-repeat;
    }
  }
  &.footer nav {
    position: static;
    text-align: center;
    width: auto;
    padding-left: 0;
    padding-top: 0;
    transform: none;
    box-shadow: none;
    a,
    a:visited {
      border: none;
      opacity: 0.8;
    }
  }
  nav {
    position: absolute;
    width: 100vw;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 2px 4px 4px rgba(0, 0, 0, 0.2);
    left: 50%;
    transform: translate(-50%, 0);
    top: -220px;
    transition: top 0.5s ease-in-out, background-color 0.5s ease-in-out;
    text-align: left;
    padding-top: 70px;
    padding-left: 3rem;
    padding-bottom: 1rem;
    &[data-open] {
      top: 0;
      background-color: white;
    }
    display: flex;
    flex-direction: column;
    @media (min-width: 1024px) {
      flex-direction: row;
      gap: 20px;
      position: static;
      text-align: center;
      width: auto;
      height: auto;
      padding-top: 0;
      padding-left: 0;
      padding-bottom: 0;
      transform: none;
      box-shadow: none;
      a,
      a:visited {
        border-top: none !important;
        width: initial !important;
      }
    }
    a,
    a:visited {
      padding: 6px;
      width: calc(100vw - 6rem);
      color: var(--text-color);
      text-decoration: none;
      border-top: 1px solid silver;
      &:not(.active):hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    a.active {
      color: var(--primary-color);
      font-weight: bold;
    }
  }
  > div {
    text-align: right;
  }
  &[data-variant="transparent"] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    @media (min-width: 1024px) {
      padding: 2.5vw 48px;
    }
    a,
    a:visited {
      color: white;
    }
    nav[data-open] {
      a,
      a:visited {
        color: var(--text-color);
      }
    }
    .menu {
      background: url(${ic_menu2}) no-repeat;
    }
    .menu[data-open] {
      background: url(${ic_menu_close}) no-repeat;
    }
  }
`;
/*
const LoginButton = styled.a`
  background-color: transparent;
  border: 2px solid var(--border-color);
  border-radius: 27px;
  height: 54px;
  min-width: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: var(--text-color);
  &:hover {
    background-color: var(--hover-color);
    cursor: pointer;
  }
  &:active,
  &:visited {
    color: var(--text-color);
    text-decoration: none;
  }
  &:active {
    background-color: var(--border-color);
  }
`;
*/
const year = new Date().getFullYear();

const Header = ({ variant, footer }: { variant?: string; footer?: boolean }) => {
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);
  return (
    <HeaderWrapper className={footer ? "footer" : ""} data-variant={variant}>
      {!footer && (
        <div id="mobile-menu" className="menu" data-open={open || undefined} onClick={() => setOpen(x => !x)} />
      )}
      <a href="/" className="logo">
        <img src={variant === "transparent" && !open ? logo2 : Logo} alt="NickNack" />
      </a>
      <nav data-open={open || undefined}>
        <NavLink id={`${footer ? "footer" : "header"}-home-link`} to="/" exact onClick={close}>
          Home
        </NavLink>
        <a id={`${footer ? "footer" : "header"}-contact-us`} href="#contact-us" onClick={close}>
          Support
        </a>
        <NavLink id={`${footer ? "footer" : "header"}-terms-link`} to="/terms" onClick={close}>
          Terms of service
        </NavLink>
        <NavLink id={`${footer ? "footer" : "header"}-privacy-link`} to="/privacy" onClick={close}>
          Privacy
        </NavLink>
      </nav>
      {footer && <div>© {year} NickNack</div>}
    </HeaderWrapper>
  );
};

export default Header;
