import styled from "@emotion/styled";
import logo_ondeck from "../assets/logos/logo_ondeck.png";
import logo_personetics from "../assets/logos/logo_personetics.png";
import logo_taboola from "../assets/logos/logo_taboola.png";
import logo_wscsports from "../assets/logos/logo_wscsports.png";
import Carousel from "react-img-carousel";

require("react-img-carousel/lib/carousel.css");

const partners = [logo_ondeck,  logo_personetics, logo_taboola, logo_wscsports];

const Wrapper = styled.div`
  text-align: center;
  padding: 3vw 2vw;

  h1 {
    margin: 0.5em 0;
    font-size: 28px;
    font-weight: 400;
    @media (min-width: 1024px) {
      margin: 0 0 1em;
      font-size: 43px;
    }
  }
  li {
    margin-left: 20px !important;
    width: 100px !important;
    @media (min-width: 1024px) {
      margin-right: 100px !important;
      width: 150px !important;
    }
  }
  img {
    height: 100% !important;
    width: 100% !important;
    object-fit: contain;
  }
`;
const LogoCarousel = () => {
  return (
    <Wrapper>
      <h1>Our favorite partners</h1>
      <Carousel
        dots={false}
        arrows={false}
        infinite
        autoplay
        autoplaySpeed={1000}
        transition="slide"
        transitionDuration={2000}
        easing="linear"
      >
        {partners.map((p, i) => (
          <img key={i} src={p} alt="" />
        ))}
        {partners.map((p, i) => (
          <img key={partners.length + i} src={p} alt="" />
        ))}
      </Carousel>
    </Wrapper>
  );
};
export default LogoCarousel;
