import CampaignManager from "./CampaignManager";

const DEEP_LINK_DOMAIN = "nnapp",
  IOS_APP_ID = "1506784055",
  IOS_BUNDLE_ID = "com.nicknack.app",
  ANDROID_PACKAGE_NAME = "com.nicknack.app";

const web = CampaignManager.forwardQuery("https://web.nicknack.app");
const web_reg_complete = CampaignManager.forwardQuery("https://web.nicknack.app");
web_reg_complete.searchParams.set("reg_complete", "true");

/**
 * https://firebase.google.com/docs/dynamic-links/create-manually
 */
const firebaseLink = (link: URL, type?: string, alt_web?: URL) => {
  const url = new URL(`https://${DEEP_LINK_DOMAIN}.page.link`);
  url.searchParams.set("apn", ANDROID_PACKAGE_NAME);
  url.searchParams.set("ibi", IOS_BUNDLE_ID);
  if (type === "ios") {
    url.searchParams.set("isi", IOS_APP_ID); // This opens up the App Store if used on a Mac
  }
  url.searchParams.set("link", alt_web ? alt_web.href : web.href);
  url.searchParams.set("ofl", link.href);
  return url.href;
};

const constructMSTeamsDeepLink = () => {
  const internalURL = CampaignManager.forwardQuery("https://internal.nicknack.app");

  // Old URL to start a chat : "https://teams.microsoft.com/l/chat/0/0?users=28:dfba90ee-77b9-44a8-bd53-86135837f5f4&message=Help"
  /*
  const url = new URL("https://teams.microsoft.com/l/entity/db48efc4-7573-4d12-86c1-08dc65a7da83/challenges");
  url.searchParams.set(
    "context",
    JSON.stringify({
      subEntityId: "/challenges?" + internalURL.searchParams.toString(),
    })
  );
*/

  const url = new URL("https://teams.microsoft.com/l/app/db48efc4-7573-4d12-86c1-08dc65a7da83");
  url.searchParams.set("source", internalURL.searchParams.toString());

  return url.href;
};

const links: { [key: string]: string } = {
  terms:
    "https://firebasestorage.googleapis.com/v0/b/nick-nack.appspot.com/o/static%2Fterms.html?alt=media&token=712ddce8-4cfb-4578-93f8-509d0f4f1019",
  privacy:
    "https://firebasestorage.googleapis.com/v0/b/nick-nack.appspot.com/o/static%2Fprivacy_policy.html?alt=media&token=62a3caf9-13c3-4d4a-8228-66cee78dff87",
  installSlack: CampaignManager.forwardQuery("https://bots-api.nicknack.app/slack/install").href,
  installTeams: constructMSTeamsDeepLink(),
  installAndroid: firebaseLink(
    CampaignManager.forwardQuery("https://play.google.com/store/apps/details?id=" + ANDROID_PACKAGE_NAME)
  ),
  installIOS: firebaseLink(
    CampaignManager.forwardQuery("https://apps.apple.com/us/app/nicknack/id" + IOS_APP_ID),
    "ios"
  ),
  web: web.href,
  app: firebaseLink(web),
  app_reg_complete: firebaseLink(web_reg_complete, "web", web_reg_complete),
};

export default links;
