import { Route, Switch, Redirect } from "react-router-dom";
import SignUpStep2 from "./SignUpStep2";
import SignUpStep3 from "./SignUpStep3";
import SignUpRegistered from "./SignUpRegistered";
import AuthProvider from "./AuthProvider";

const SignUpIndex = () => {
  return (
    <AuthProvider>
      <Switch>
        <Route path="/signup/step-2">
          <SignUpStep2 />
        </Route>
        <Route path="/signup/step-3/:name">
          <SignUpStep3 />
        </Route>
        <Route path="/signup/registered">
          <SignUpRegistered />
        </Route>
        <Route>
          <Redirect to="/signup/step-2" />
        </Route>
      </Switch>
    </AuthProvider>
  );
};

export default SignUpIndex;
