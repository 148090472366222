import { FormEventHandler, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import StepLayout from "./StepLayout";
import { useAuth, signOut } from "./AuthProvider";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import squiggle from "./assets/squiggle-flat.svg";
import CampaignManager from "../services/CampaignManager";
import Spinner from "./Spinner";

const squiggleAnimation = keyframes`
  0% { background-position: 0; }
  100% { background-position: 18px; }
`;

const Squiggle = styled.div`
  display: inline-block;
  position: relative;
  cursor: pointer;
  &:after {
    display: block;
    content: "";
    height: 4px;
    background: url(${squiggle}) repeat-x;
  }
  &:hover {
    color: var(--primary-color);
    &:after {
      animation: ${squiggleAnimation} 0.25s linear infinite;
    }
  }
  img {
    width: 1em;
    height: 1em;
    object-fit: contain;
    border: 1px solid silver;
    border-radius: 50%;
    margin-right: 0.3em;
  }
  > div {
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    right: 0;
    bottom: -1.3em;
  }
`;

const SignUpStep2 = () => {
  const auth = useAuth();
  const history = useHistory();
  const [details, setDetails] = useState<any>(null);
  const [form, setForm] = useState({ name: "", allow_email_domain: false });
  const [sending, setSending] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    (async () => {
      if (!auth) return;
      const token = await auth.getIdToken();
      const res = await fetch(CampaignManager.forwardQuery(window.location.origin + "/api/check-user").href, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });
      return res.json();
    })()
      .then(details => {
        if (details.registered || details.can_login) {
          history.push("/signup/registered");
        } else {
          setDetails(details);
          setForm(f => ({ ...f, name: details.organization || "" }));
        }
      })
      .catch(e => {
        console.error(e);
        setError("There was something wrong, please try again");
      });
  }, [auth, history]);

  const createOrganization: FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();
    e.stopPropagation();
    if (!auth) return;

    setSending(true);
    try {
      const token = await auth.getIdToken();
      const res = await fetch(CampaignManager.forwardQuery(window.location.origin + "/api/create-organization").href, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      });
      const body = await res.json();
      if (body.success) {
        history.push("/signup/step-3/" + encodeURIComponent(form.name), body);
      } else if (body.error) {
        setError(body.error);
      } else if (body.registered || body.can_login) {
        history.push("/signup/registered");
      }
      setSending(false);
    } catch (e) {
      setError(e.message);
      setSending(false);
    }
  };

  if (!details) {
    return (
      <StepLayout>
        {error || <Spinner />}
        {error && <br />}
        {error && (
          <input
            type="submit"
            value="Sign out"
            onClick={e => {
              e.preventDefault();
              signOut(true).then(() => {
                history.push("/signup");
              });
            }}
          />
        )}
      </StepLayout>
    );
  }

  return (
    <StepLayout fill>
      <h1>
        So,{" "}
        <Squiggle
          onClick={() =>
            signOut(true).then(() => {
              history.push("/signup");
            })
          }
        >
          <img
            src={auth.photoURL}
            alt=" "
            style={{ display: imgLoaded ? "inline" : "none" }}
            onLoad={() => setImgLoaded(true)}
            onError={() => setImgLoaded(false)}
          />
          {auth.displayName}
          <div>Not you? Change here</div>
        </Squiggle>
      </h1>
      <br />
      <h2>How do you want to call your new team?</h2>
      <form onSubmit={createOrganization}>
        <input
          type="text"
          autoFocus
          value={form.name}
          placeholder="Acme Corp."
          required
          onChange={e => setForm(f => ({ ...f, name: e.target.value }))}
          style={{ width: "400px", maxWidth: "90vw", marginTop: "0.75em" }}
        />
        <br />
        <sup style={{ fontSize: "14px", color: "grey" }}>This is what connects you and your peers/friends</sup>
        <br />
        {!details.email_provider && (
          <label>
            <input
              type="checkbox"
              checked={form.allow_email_domain}
              onChange={e => setForm(f => ({ ...f, allow_email_domain: e.target.checked }))}
            />{" "}
            Allow anyone with a <strong>@{details.email_domain}</strong> email address to join?
          </label>
        )}
        <br />
        <input type="submit" disabled={sending || !form.name} id="signup-step2-next" />
        <br />
        {error && <div className="error">Error: {error}</div>}
      </form>
    </StepLayout>
  );
};

export default SignUpStep2;
