import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

const load = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  --spinner-color: var(--primary-color);
  --spinner-background-color: white;
  --spinner-size: 6em;
  --spinner-size-0-1: calc(0.1 * var(--spinner-size));
  --spinner-size-1-02: calc(1.03 * var(--spinner-size));
  --spinner-size-0-51: calc(0.52 * var(--spinner-size));
  --spinner-size-0-52: calc(0.53 * var(--spinner-size));

  display: inline-block;
  color: var(--spinner-color);
  font-size: 16px;
  margin: 1em;
  position: relative;
  width: var(--spinner-size);
  height: var(--spinner-size);
  box-shadow: inset 0 0 0 var(--spinner-size-0-1);
  border-radius: 50%;
  border-width: 0;

  &:before,
  &:after {
    position: absolute;
    content: "";
    border-radius: 50%;
    border-width: 0;
  }

  &:before {
    width: var(--spinner-size-0-52);
    height: var(--spinner-size-1-02);
    background: var(--spinner-background-color);
    border-radius: var(--spinner-size-1-02) 0 0 var(--spinner-size-1-02);
    top: -0.1em;
    left: -0.1em;
    transform-origin: var(--spinner-size-0-52) var(--spinner-size-0-51);
    animation: ${load} 1300ms infinite ease 1.5s;
  }
  &:after {
    width: var(--spinner-size-0-52);
    height: var(--spinner-size-1-02);
    background: var(--spinner-background-color);
    border-radius: 0 var(--spinner-size-1-02) var(--spinner-size-1-02) 0;
    top: -0.1em;
    left: var(--spinner-size-0-51);
    transform-origin: 0 var(--spinner-size-0-51);
    animation: ${load} 1300ms infinite ease;
  }
`;

export default Spinner;
