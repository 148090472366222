import {keyframes} from "@emotion/react";
import styled from "@emotion/styled";
import logo_papayaglobal from "../assets/logos/logo_papayaglobal.png";

const scale = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;

const AnnLink = styled.a`
  text-align: center;
  font-weight: 600;
  letter-spacing: .18px;
  opacity: 1;
  text-decoration: none;
  width: auto;
  font-size: 24px;
  color: black;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  animation-duration: .5s;
  animation-fill-mode: both;
  animation-name: ${scale};
  animation-delay: 0.3s;
`

const Container = styled.div`
  width: 100%;
  text-align: center;
  padding: 20px 0;
  background: linear-gradient(135deg, #ffe0e0 43%,#fff5f2 51%);
`

const Announcement = ({ url }: {url: string}) => {
  return <Container>
    <AnnLink href={url} >We are now part of &nbsp; <img src={logo_papayaglobal} alt="papayaglobal" /> &nbsp; &nbsp; &nbsp; <u>Read the announcement &gt;</u></AnnLink>
  </Container>
}

export default Announcement;