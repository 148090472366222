import img_article from "../assets/img_article.jpg";
import img_article_2 from "../assets/img_article_2.png";
import styled from "@emotion/styled";

const ReadMoreWrapper = styled.article`
  text-align: center;
  max-width: 1024px;
  margin: 0 auto;
  padding: 8vw;
  @media (min-width: 1024px) {
    padding: 4vw;
  }
  h1 {
    margin-top: 0;
    margin-bottom: 4vw;
    font-size: 28px;
    font-weight: 400;
    @media (min-width: 1024px) {
      font-size: 43px;
    }
  }
  h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    @media (min-width: 1024px) {
      font-size: 26px;
      line-height: 32px;
    }
  }
  p {
    font: 16px "Open Sans", sans-serif;
  }
  a {
    color: var(--primary-color);
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  img {
  }
  .articles {
    display: flex;
    gap: 3rem;
    align-items: flex-start;
    flex-direction: column;
    @media (min-width: 1024px) {
      flex-direction: row;
    }
    article {
      flex: 1 1 50%;
    }
  }
`;

const ReadAboutUsArticle = () => {
  return (
    <ReadMoreWrapper>
      <h1>Read about us</h1>
      <div className="articles">
        <article>
          <img src={img_article} alt="" />
          <h3>Why Zoom happy hours don't work (But what can)</h3>
          <p>
            When I worked at Intel, we used to have an urban legend that if you want to close something with the CEO,
            you should ambush her on her way...
          </p>
          <div>
            <a
              href="https://ishenfeld.medium.com/why-zoom-happy-hours-dont-work-but-what-can-b4d4d594ee84"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read on Medium
            </a>
            <span style={{ marginLeft: "1rem", marginRight: "1rem" }}>|</span>4 min read
          </div>
        </article>
        <article>
          <img src={img_article_2} alt="" />
          <h3>
            Three things HR needs hyper-growth managers to do and why it’s not a realistic expectation without tech
          </h3>
          <p>There are several things that any manager growing their team should do...</p>
          <div>
            <a
              href="https://ishenfeld.medium.com/three-things-hr-needs-hyper-growth-managers-to-do-and-why-its-not-a-realistic-expectation-without-497798d8b4b"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read on Medium
            </a>
            <span style={{ marginLeft: "1rem", marginRight: "1rem" }}>|</span>4 min read
          </div>
        </article>
      </div>
    </ReadMoreWrapper>
  );
};

export default ReadAboutUsArticle;
