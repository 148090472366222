import React from "react";
import { Switch, Route } from "react-router-dom";
import Homepage from "./components/Homepage";
import GlobalStyles from "./GlobalStyles";
import Header from "./components/Header";
import styled from "@emotion/styled";
import ExternalRedirect from "./ExternalRedirect";
import { CookieConsentProvider } from "./services/CookieConsentProvider";
import links from "./services/links";
import Landing3 from "./components/landing-pages/qa/Landing3";
import LandingPageQAAlt from "./components/landing-pages/qa-alt/LandingPageQAAlt";
import SignUpIndex from "./signup/SignUpIndex";

const FullPageIframe = styled.iframe`
  width: 100vw;
  height: 200vh;
  border: none;
  background: var(--bg-grey);
`;

function App() {
  return (
    <>
      <GlobalStyles />
      <Switch>
        <Route path="/install/slack">
          <ExternalRedirect to={links.installSlack} />
        </Route>
        <Route path="/install/teams">
          <ExternalRedirect to={links.installTeams} />
        </Route>
        <Route path="/install/android">
          <ExternalRedirect to={links.installAndroid} />
        </Route>
        <Route path="/install/ios">
          <ExternalRedirect to={links.installIOS} />
        </Route>
        <Route path="/install/web">
          <ExternalRedirect to={links.web} />
        </Route>
        <Route>
          <CookieConsentProvider>
            <Switch>
              <Route path="/lp/qa">
                <Landing3 />
              </Route>
              <Route path="/lp/qa-alt">
                <LandingPageQAAlt />
              </Route>
              <Route path="/terms">
                <Header />
                <FullPageIframe src={links.terms} />
              </Route>
              <Route path="/privacy">
                <Header />
                <FullPageIframe src={links.privacy} />
              </Route>
              <Route path="/signup">
                <SignUpIndex />
              </Route>
              <Route>
                <Header />
                <Homepage />
              </Route>
            </Switch>
          </CookieConsentProvider>
        </Route>
      </Switch>
    </>
  );
}

export default App;
