import { Global, css } from "@emotion/react";

const globalStyles = css`
  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }

  :root {
    --primary-color: #ec185e;
    --secondary-color: #2088e8;
    --text-color: #1f1e1e;
    --border-color: #e5e5e5;
    --hover-color: #f3f2f2;
    --gradient-1: #df5098;
    --gradient-2: #4690f0;
    --bg-grey: #f4f5f9;
    --primary-color-darken: #c7134f;
    --input-border-color: #a198a1;
    --disabled-color: #c9c6c9;
  }

  html {
    font: 16px "Raleway", sans-serif;
    color: var(--text-color);
  }

  body,
  #root {
    margin: 0;
    min-height: 100vh;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }

  textarea,
  input[type="text"],
  input[type="email"] {
    width: 100%;
    padding: 10px 20px;
    border: 1px solid var(--input-border-color);
    border-radius: 6px;
  }

  button,
  select {
    text-transform: none;
  }

  input[type="submit"] {
    color: white;
    background-color: var(--primary-color);
    border: none;
    font-size: 14px;
    border-radius: 6px;
    padding: 10px 40px;
    margin: 20px;
    cursor: pointer;

    &:hover,
    &:active {
      background-color: var(--primary-color-darken);
    }

    &:disabled {
      color: black;
      background-color: var(--disabled-color);
      cursor: not-allowed;
    }
  }

  img {
    max-width: 100%;
  }

  ::-webkit-input-placeholder {
    font-style: italic;
  }

  :-moz-placeholder {
    font-style: italic;
  }

  ::-moz-placeholder {
    font-style: italic;
  }

  :-ms-input-placeholder {
    font-style: italic;
  }
`;

const GlobalStyles = () => {
  return <Global styles={globalStyles} />;
};

export default GlobalStyles;
