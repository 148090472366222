import styled from "@emotion/styled";
import img_num_1 from "../assets/ass2/img_num_1.png";
import img_num_2 from "../assets/ass2/img_num_2.png";
import img_num_3 from "../assets/ass2/img_num_3.png";

const FeaturesArticleWrapper = styled.article`
  background-color: var(--bg-grey);
  text-align: center;
  padding: 4vw 2vw;

  h1 {
    margin: 0;
    font-size: 28px;
    font-weight: 400;
    @media (min-width: 1024px) {
      font-size: 43px;
    }
  }
  h4 {
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    color: var(--secondary-color);
    @media (min-width: 1024px) {
      font-size: 28px;
      line-height: 36px;
    }
  }
  section {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 0 2rem;

    @media (min-width: 1024px) {
      gap: 40px;
      align-items: flex-start;
      flex-direction: row;
    }
    article {
      flex-basis: 33%;
      text-align: center;
    }
    p {
      font: 16px "Open Sans", sans-serif;
      line-height: 20px;
      @media (min-width: 1024px) {
        line-height: 28px;
      }
      color: #1d1c1c;
    }
  }
  figure[data-img] {
    width: 90%;
    height: 180px;

    @media (min-width: 1024px) {
      width: 24vw;
      height: 16vw;
    }
    margin: 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    &:before {
      position: absolute;
      display: flex;
      align-items: center;
      place-content: center;
      font-size: 1.5rem;
      font-weight: 600;
      color: var(--secondary-color);
      background: white;
      content: attr(data-img);
      width: 3rem;
      height: 3rem;
      box-shadow: 0 4px 4px 2px rgba(0, 0, 0, 0.1);
      border-radius: 50%;
    }
  }
  figure[data-img="1"] {
    background-image: url(${img_num_1});
  }
  figure[data-img="2"] {
    background-image: url(${img_num_2});
  }
  figure[data-img="3"] {
    background-image: url(${img_num_3});
  }
`;

const FeaturesArticle = () => {
  return (
    <FeaturesArticleWrapper>
      <h1>Energize your team</h1>
      <p>with fun and meaningful connections</p>
      <section>
        <article>
          <h4>Play</h4>
          <figure data-img="1" />
          <p>Enjoy more than 30 team challenges</p>
        </article>
        <article>
          <h4>Create</h4>
          <figure data-img="2" />
          <p>Collaborate on fun videos and stories</p>
        </article>
        <article>
          <h4>Check in</h4>
          <figure data-img="3" />
          <p>Jump on a coffee break and chat with whomever is around</p>
        </article>
      </section>
    </FeaturesArticleWrapper>
  );
};

export default FeaturesArticle;
